<template>
    <div style="text-align: center">
        <label>{{ t('DOCUMENTS.DEADLINE') }}:</label>
        <div>{{ formatDate(items.iten.deadline_at) }}</div>
    </div>
</template>

<script>
import documentMixin from '../../../mixins/documentMixin';
import {useI18n} from 'vue-i18n';
import {useToast} from 'vue-toastification';
export default {
    name: "slotDateDeadlineComponent",
    props:['items'],
    setup() {
        const {t} = useI18n();
        const toast = useToast();
        return {t, toast}
    },
    mixins:[documentMixin]
}
</script>

<style scoped>

</style>