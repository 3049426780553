<template>
    <div class="card">
        <div class="card-header"></div>
        <div class="card-body pt-0">
            <div class="text-center">
                <h5>{{ msg }}</h5>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "noneFoundComponent",
    props: ['msg']
}
</script>

<style scoped>

</style>