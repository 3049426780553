<template>
<div>
    <div
            class="list-primary hide-text pt-0">{{ items.iten.name }}
    </div>
    <div
            class="list-primary hide-text d-inline-flex pb-0 pt-0">
        <div
                class="font-weight-bold"  :class="items?.iten?.status ? items.iten.status: 'running'">
            {{ stats(items?.iten?.status) }}
        </div>
        <div
                class="signature-quantity">{{ quantityOfSignatures(items.iten) }}
        </div>
    </div>
</div>
</template>

<script>
import {useI18n} from 'vue-i18n';
import {useToast} from 'vue-toastification';
import documentMixin from '../../../mixins/documentMixin';


export default {
name: "principalInfoComponent",
    props:['items'],
    setup() {
        const {t} = useI18n();
        const toast = useToast();
        return {t, toast}
    },
    mixins:[documentMixin]
}
</script>

<style scoped>

</style>