<template>
    <div>
        <load-screen-component
                :isLoading="isLoading"/>
        <page-header
                :title="getTitlePage()"
                :typeOfFind="'running'"
                @filter="setModalFilter" filter></page-header>
        <breadcrumb-docs-component></breadcrumb-docs-component>
        <card-filters-index-component>
            <template #input_finished_doc>
                <Select2 :settings="{ width: '100%'}"
                         :options="optionsFilterDay"
                         @select="filtering(filters)"
                         v-model="filters.last_days"
                         placeholder="selecione"
                />
            </template>
            <template #input_sistem>
                <Select2 :settings="{ width: '100%'}"
                         :options="optionsFilterSystem"
                         @select="filtering(filters)"
                         v-model="filters.sistems"
                         placeholder="selecione"
                />
            </template>
        </card-filters-index-component>
        <running-index-component v-if="context === 'Running' || context === 'PendingAdm'"></running-index-component>
        <signed-index-component v-if="context === 'Signed'"></signed-index-component>
        <all-doc-component v-if="context === 'All'"></all-doc-component>
        <canceled-index-component v-if="context === 'Canceled'"></canceled-index-component>
        <expired-index-component v-if="context === 'Expired'"></expired-index-component>
        <none-found-component
                v-if="!allDocuments?.data?.length"
                :msg="t('DOCUMENTS.MSG.NONE_DOCUMENT_FOUND')"></none-found-component>
        <modalComponent :title-modal="titleModal" modal-large>
            <template #title>{{ titleModal }}</template>
            <filterComponent
                    :statusIndex="context === 'PendingAdm' ? 'running' : context.toLowerCase()"
                    v-if="modalChoose === 1"
                    @filtering="filtering"
                    @clearFilter="clearFilter"
            >
            </filterComponent>
        </modalComponent>
        <div class="row gutters">
            <div class="col-2" v-if="!$widthMd(1000)"></div>
            <div :class="!$widthMd(1000) ? 'col-8' : 'col-12'">
                <pagination-component
                        v-if="allDocuments.data && allDocuments?.data?.length"
                        :items="allDocuments"
                        :to="`/${context.toLowerCase()}`"
                        @changePage="changePage($event)"></pagination-component>
            </div>
            <div class="col-2" v-if="!$widthMd(1000)">
                <div class="row gutters d-flex justify-content-end" v-if="allDocuments && allDocuments.data && allDocuments.data[0]">
                    <div class="col-8">
                        <label>Por página: </label>
                        <Select2 :settings="{width: '100%'}" placeholder="Items por Página" @select="changePaginate"
                                 :options="paginate"
                                 id="paginate" name="paginate"
                                 v-model="perPage"
                        ></Select2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BreadcrumbDocsComponent from '@/pages/documents/.partials/BreadcrumbDocsComponent';
import loadScreenComponent from '../../components/layouts/loadScreenComponent';
import pageHeader from '../../components/layouts/pageHeader';
import runningIndexComponent from '@/pages/documents/.partials/runningIndexComponent';
import signedIndexComponent from '@/pages/documents/.partials/signedIndexComponent';
import allDocComponent from '@/pages/documents/.partials/allDocComponent';
import canceledIndexComponent from '@/pages/documents/.partials/canceledIndexComponent';
import filterComponent from '@/pages/documents/.partials/filterComponent';
import modalComponent from '@/components/layouts/modalComponent';
import {mapActions, mapMutations, mapState} from 'vuex';
import PaginationComponent from '../../components/layouts/PaginationComponent';
import noneFoundComponent from '../../components/layouts/noneFoundComponent';
import expiredIndexComponent from '@/pages/documents/.partials/expiredIndexComponent';
import Select2 from 'vue3-select2-component';
import {useI18n} from 'vue-i18n';
import {useToast} from 'vue-toastification';
import cardFiltersIndexComponent from '@/pages/documents/.partials/cardFiltersIndexComponent';


export default {
    name: "indexDocumentsComponent",
    components: {
        BreadcrumbDocsComponent,
        loadScreenComponent,
        pageHeader,
        runningIndexComponent,
        signedIndexComponent,
        allDocComponent,
        PaginationComponent,
        canceledIndexComponent,
        filterComponent,
        modalComponent,
        noneFoundComponent,
        expiredIndexComponent,
        Select2,
        cardFiltersIndexComponent,
    },
    data() {
        return {
            optionsFilterDay: [
                {id: 'all', text: 'Todos os Documentos'},
                {id: '7', text: 'Últimos 07 Dias'},
                {id: '15', text: 'Últimos 15 Dias'},
                {id: '20', text: 'Últimos 20 Dias'},
                {id: 'lastMonth', text: 'Último Mês'},
                {id: 'monthNow', text: 'Mês Atual'},
            ],
            optionsFilterSystem:[
                {id: 'all', text: 'Todos'},
                {id: 'R2Disponibilidade', text: 'Disponibilidade'},
                {id: 'R2Loteamentos', text: 'Loteamentos'},
            ],
            context: this.$route.name,
            modalChoose: null,
            titleModal: '',
            isLoading: null,
            filters: {},
            perPage: null,
            paginate: [
                {id: 'selecione', text: 'Selecione'},
                {id: 25, text: 25},
                {id: 50, text: 50},
                {id: 75, text: 75},
                {id: 100, text: 100},
            ],
        }
    },
    setup() {
        const {t} = useI18n();
        const toast = useToast();
        return {t, toast}
    },
    computed: {
        ...mapState({
            allDocuments: (state) => state.document.allDocuments,
            reloadIndex: (state) => state.document.reloadIndex,
            filtersHome: (state) => state.home.filtersHome,
        })
    },
    watch: {
        $route(to) {
            this.setFilterHome({last_days: 'all'});
            this.filters = {last_days: 'all'};
            this.context = to.name;
            this.getIndexDocument({filter: this.context.toLowerCase(), filters: this.filters, perpage: this.perPage});
        },
        reloadIndex(val)
        {
            if (val){
                this.getIndexDocument({filter: this.context.toLowerCase(), page: 1, filters: this.filters, perpage: this.perPage});
                this.$store.commit('reloadIndex', false);
            }
        },
    },
    mounted() {
        this.filters = this.filtersHome;
        this.getIndexDocument({filter: this.context.toLowerCase(), filters: this.filters, perpage: this.perPage});
    },
    methods: {
        ...mapMutations({
            setFilterHome:'setFilterHome',
        }),
        ...mapActions({
            getIndexDocument: 'getIndexDocument'
        }),
        getTitlePage() {
            switch (this.context) {
                case 'Running':
                    return 'Documentos Pendentes';
                case 'PendingAdm':
                    return 'Documentos Pendentes para Administração';
                case 'Signed':
                    return 'Documentos Assinados';
                case 'Canceled':
                    return 'Documentos Cancelados';
                case 'Expired':
                    return 'Documentos Expirados';
            }
            return 'Documentos';
        },
        changePage(page) {
            this.getIndexDocument({filter: this.context.toLowerCase(), page: page, filters: this.filters, perpage: this.perPage});
        },
        setModalFilter() {
            this.modalChoose = 1;
            this.titleModal = 'Filtrar';
        },
        filtering(filter) {
            document.getElementById('closeX')?.click();
            this.filters = filter;
            this.getIndexDocument({filter: this.context.toLowerCase(), filters: this.filters, perpage: this.perPage});
        },
        changePaginate(){
            if(this.perPage === 'selecione'){
                this.perPage = null;
            }
            this.getIndexDocument({filter: this.context.toLowerCase(), filters: this.filters, perpage: this.perPage});
        },
        clearFilter() {
            document.getElementById('closeX')?.click();
            this.filters = {};
            this.getIndexDocument({filter: this.context.toLowerCase(), perpage: this.perPage});
        },
    }
}
</script>

<style>
.closed {
    background-color: #3FBF3F;
    width: 80px;
    font-size: 11px;
    border-radius: 1em;
    color: #EBF1EB;
    justify-content: center;
    align-items: center;
    padding: 2px 5px 2px 5px;
    display: flex;
}

.running {
    background-color: #fb7f12;
    width: 80px;
    font-size: 11px;
    color: #EBF1EB;
    border-radius: 1em;
    justify-content: center;
    align-items: center;
    padding: 2px 5px 2px 5px;
    display: flex;
}

.canceled {
    background-color: #f34646;
    width: 80px;
    color: #EBF1EB;
    font-size: 11px;
    border-radius: 1em;
    justify-content: center;
    align-items: center;
    padding: 2px 5px 2px 5px;
    display: flex;
}

.expired {
    background-color: #e0bb47;
    width: 80px;
    color: #EBF1EB;
    font-size: 11px;
    border-radius: 1em;
    justify-content: center;
    align-items: center;
    padding: 2px 5px 2px 5px;
    display: flex;
}

.signer-canceled {
    display: inline-block;
    background-color: #f34646;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

.signer-signed {
    display: inline-block;
    background-color: #3FBF3F;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

.signer-running {
    display: inline-block;
    width: 20px;
    border-radius: 50%;
    height: 20px;
    background-color: #fb7f12;
}

.teste {
    width: 6px;
    position: center;
    height: 4px;
    box-shadow: #3a424c;
    text-align: center;
    border-radius: 50%;
}

.date {
    display: inline-block;
    margin-left: 45%;
}

.whats {
    display: inline-block;
    margin-left: 45%;
    float: right;
    font-size: 15px;
}

.signature-quantity {
    font-size: 15px;
    margin-left: 5px;
    margin-top: 3px;
    color: #3a424c;
}

.seller {
    margin-left: 24px;
    font-size: 12px;
    color: #5f5f5f;
}

.name-signer {
    display: inline-block;
    width: 500px;
    margin-top: -3px;
    position: relative;
    margin-left: 23px;
}

[data-tooltip] {
    position: absolute;
    cursor: pointer;
}

[data-tooltip]:before,
[data-tooltip]:after {
    line-height: 1;
    font-size: .9em;
    pointer-events: none;
    position: absolute;
    box-sizing: border-box;
    display: none;
    opacity: 0;
}

[data-tooltip]:before {
    content: "";
    border: 5px solid transparent;
    z-index: 100;
}

[data-tooltip]:after {
    content: attr(data-tooltip);
    text-align: center;
    min-width: 3em;
    max-width: 21em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 7px 8px;
    border-radius: 3px;
    background: #2B2525;
    color: #F5F5F5;
    z-index: 99;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
    display: block;
    opacity: 1;
}

[data-tooltip]:not([data-flow])::before,
[data-tooltip][data-flow="top"]::before {
    bottom: 100%;
    border-bottom-width: 0;
    border-top-color: #2B2525;
}

[data-tooltip]:not([data-flow])::after,
[data-tooltip][data-flow="top"]::after {
    bottom: calc(100% + 5px);
}

[data-tooltip]:not([data-flow])::before, [tooltip]:not([data-flow])::after,
[data-tooltip][data-flow="top"]::before,
[data-tooltip][data-flow="top"]::after {
    left: 50%;
    -webkit-transform: translate(-50%, -4px);
    transform: translate(-50%, -4px);
}

[data-tooltip][data-flow="bottom"]::before {
    top: 100%;
    border-top-width: 0;
    border-bottom-color: #0F0738;
}

[data-tooltip][data-flow="bottom"]::after {
    top: calc(100% + 5px);
}

[data-tooltip][data-flow="bottom"]::before, [data-tooltip][data-flow="bottom"]::after {
    left: 50%;
    -webkit-transform: translate(-50%, 8px);
    transform: translate(-50%, 8px);
}

[data-tooltip][data-flow="left"]::before {
    top: 50%;
    border-right-width: 0;
    border-left-color: #0F0738;
    left: calc(0em - 5px);
    -webkit-transform: translate(-8px, -50%);
    transform: translate(-8px, -50%);
}

[data-tooltip][data-flow="left"]::after {
    top: 50%;
    right: calc(100% + 5px);
    -webkit-transform: translate(-8px, -50%);
    transform: translate(-8px, -50%);
}

[data-tooltip][data-flow="right"]::before {
    top: 50%;
    border-left-width: 0;
    border-right-color: #0F0738;
    right: calc(0em - 5px);
    -webkit-transform: translate(8px, -50%);
    transform: translate(8px, -50%);
}

[data-tooltip][data-flow="right"]::after {
    top: 50%;
    left: calc(100% + 5px);
    -webkit-transform: translate(8px, -50%);
    transform: translate(8px, -50%);
}

[data-tooltip=""]::after, [data-tooltip=""]::before {
    display: none !important;
}

.first {
    border-radius: 0px;
    background: transparent;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 1.5rem 0;
    font-size: 1.5rem;
    align-items: center;
}
</style>