<template>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
        <div class="card-body card-body-phone pb-2 pt-2">
            <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center success aling-pagination">
                    <li class="page-item" :class="{disabled:items.current_page===1?true:false}">
                        <router-link class="page-link" :to="to" @click="pageChange(1)"><span class="menu-text"><i
                                class="icon-chevrons-left"/></span></router-link>
                    </li>
                    <li class="page-item" :class="{disabled:items.current_page===1?true:false}">
                        <router-link class="page-link" :to="to" @click="pageChange(items.current_page-1)"><span
                                class="menu-text"><i class="icon-chevron-left1"/></span></router-link>
                    </li>
                    <li class="page-item mr-2"
                        :class="{active:item.active?'active':'', disabled:item.label==='...'?true:false}"
                        v-for="item in items.links" :key="item.key">
                        <router-link class="page-link" to="" v-if="item.label!='« Anterior' && item.label!='Próxima »'"
                                     @click="pageChange(item.label)">
                            <span class="menu-text"><span v-html="item.label"></span></span>
                        </router-link>
                    </li>
                    <li class="page-item" :class="{disabled:items.current_page===items.last_page?true:false}">
                        <router-link class="page-link" :to="to"
                                     @click="pageChange(items.current_page===items.last_page?items.last_page:items.current_page+1)">
                            <span class="menu-text"><i class="icon-chevron-right1"/></span>
                        </router-link>
                    </li>
                    <li class="page-item" :class="{disabled:items.current_page===items.last_page?true:false}">
                        <router-link class="page-link" :to="to" @click="pageChange(items.last_page)">
                            <span class="menu-text"><i class="icon-chevrons-right"/></span>
                        </router-link>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="text-center d-flex justify-content-center" >
            <div style="width: max-content;" class="pl-2 pr-2 pt-1 pb-1 mb-0" >
                Página {{items?.current_page}} de {{items?.last_page}}, exibindo {{ items?.data?.length }} registros de {{items?.total}}.
            </div>
        </div>
    </div>
</template>

<script>
import {useI18n} from "vue-i18n";

export default {
    setup() {
        const {t} = useI18n();
        return {t}
    },
    data() {
        return {
            links: {}
        }
    },
    props: {
        items: {},
        to: {
            type: String,
            default: ''
        }
    },
    created() {
        this.links = this.items
    },
    methods: {
        pageChange(page) {
            this.$emit('changePage', page)
        },
    }
}
</script>
<style scoped>
.hooverlegend:hover{
    background-color: #0e5e4e;
    color: #ffffff
}
</style>
