<template>
    <div>
        <list-component
                :items="allDocuments.data"
                v-if="allDocuments?.data?.length"
                @open="open($event)"
                :haveDetails="true">
            <template v-slot:principal-info="{items}">
                <principal-info-component :items="items"></principal-info-component>
            </template>
            <template v-slot:date="items">
                <slotDateDeadlineComponent :items="items"></slotDateDeadlineComponent>
            </template>
            <template v-slot:dropdown-options="items">
                <dropdown-options-component :items="items"></dropdown-options-component>
            </template>
            <template
                    v-slot:details-itens="items">
                <details-itens-component :items="items"></details-itens-component>
            </template>
        </list-component>
    </div>
</template>

<script>

import {useI18n} from 'vue-i18n';
import {useToast} from "vue-toastification";
import ListComponent from '@/components/layouts/listComponent';
import documentMixin from '../../../mixins/documentMixin';
import {mapState} from 'vuex';
import principalInfoComponent from './principalInfoComponent';
import slotDateDeadlineComponent from './slotDateDeadlineComponent';
import dropdownOptionsComponent from './dropdownOptionsComponent';
import detailsItensComponent from './detailsItensComponent';

export default {
    name: 'RunningIndex',
    components: {
        ListComponent,
        principalInfoComponent,
        slotDateDeadlineComponent,
        dropdownOptionsComponent,
        detailsItensComponent,
    },
    setup() {
        const {t} = useI18n();
        const toast = useToast();
        return {t, toast}
    },
    mixins: [documentMixin],
    computed: {
        ...mapState({
            allDocuments: (state) => state.document.allDocuments,
        })
    },
};

</script>
<style lang="less">

</style>
