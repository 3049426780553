<template>
    <div>
        <div class="row mw-100">
            <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 align-self-center">
                <div class="tags-block mt-1 ml-2 row left-content">
                    <div
                            class="tags pointer card  pl-2 pr-2 pt-1 pb-1 mb-1 mr-2"
                            @click="$router.push({name: 'Running'})">
                        <div class="mr-2">
                            <i
                                    class="icon-label"
                                    :style="{'color':'#fb7f12'}"></i>
                            {{ this.t('HOME.RUNNING') }}
                        </div>
                    </div>
                    <div class="tags pointer card  pl-2 pr-2 pt-1 pb-1 mb-1 mr-2"
                         @click="$router.push({name: 'PendingAdm'})">
                        <div class="mr-2">
                            <i class="icon-label" :style="{'color':'#fb7f12'}"></i>
                            {{ t('HOME.PENDING_ADM') }}
                        </div>
                    </div>
                    <div class="tags pointer card  pl-2 pr-2 pt-1 pb-1 mb-1 mr-2"
                         @click="$router.push({name: 'Signed'})">
                        <div class="mr-2">
                            <i class="icon-label"
                               :style="{'color':'#3FBF3F'}"></i>
                            {{ t('HOME.ASSINED') }}
                        </div>
                    </div>
                    <div
                            class="tags pointer card  pl-2 pr-2 pt-1 pb-1 mb-1 mr-2 background-active-header"
                            @click="$router.push({name: 'Canceled'})">
                        <div class="mr-2">
                            <i class="icon-label"
                               :style="{'color': '#f34646'}"></i>
                            {{ t('HOME.CANCELED') }}
                        </div>
                    </div>
                    <div class="tags pointer card  pl-2 pr-2 pt-1 pb-1 mb-1 mr-2"
                         @click="$router.push({name: 'Expired'})">
                        <div class="mr-2">
                            <i class="icon-label"
                               :style="{'color':'#e0bb47'}"></i>
                            {{ t('HOME.EXPIRED') }}
                        </div>
                    </div>
                </div>
            </div>
            <div
                    class="col-2 text-right pr-0"
                    v-if="false">
                <button
                        data-cy="lot_index_dropdown"
                        class="btn btn-custom bg-btn no-border"
                        type="button"
                        id="import-buttom"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                    <i
                            class="icon-menu"/>
                </button>
                <div
                        class="dropdown-menu dropdown-menu-right dropdown-black dropdown-lot w-auto"
                        id="dropdown-import">
                    <div class="dropdown-item bg-hover-black w-auto">
                        <a class="row item-custom2 ml-1 text-white pr-2">
                            <i
                                    class="icon-download mr-1 font-15"/>
                            {{t('EXPORTS.XLSX')}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useI18n} from 'vue-i18n';
import {useToast} from 'vue-toastification';

export default {
    name: "BreadcrumbDocsComponent",
    setup() {
        const {t} = useI18n();
        const toast = useToast();
        return {t, toast}
    },
}
</script>

<style scoped>

</style>