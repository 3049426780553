<template>
    <div>
        <div
                v-if="items.iten.status === null || items.iten.status === 'running'"
                class="dropdown-item pointer text-white"
                @click="showAlert(items.iten.id, items.iten.access_token)">
            <i
                    class="icon-cancel mr-2 font-15"/>{{ t('DOCUMENTS.CANCEL') }}
        </div>
        <div class="dropdown-item pointer text-white"
             @click="showDocument(items.iten)">
            <i
                    class="icon-eye1 mr-2 font-15"/>{{ t('DOCUMENTS.VIEW_DOC_ORIGINAL') }}
        </div>
        <div
                v-if="items.iten.status === 'closed'"
                class="dropdown-item pointer text-white"
                @click="showDocumentSigned(items.iten)">
            <i
                    class="icon-eye mr-2 font-15"/>{{ t('DOCUMENTS.VIEW_DOC_ALREADY_SIGN') }}
        </div>
        <div class="dropdown-item pointer text-white"
             v-if="items.iten.status === 'running' && getSignersNotSigned(items.iten)"
             @click="notifyAllPendin(items.iten)">
            <i
                    class="icon-insert_comment mr-2 font-15"/>{{ t('DOCUMENTS.NOTIFY_PENDING') }}
        </div>
    </div>
</template>

<script>
import {useI18n} from 'vue-i18n';
import {useToast} from 'vue-toastification';
import documentMixin from '../../../mixins/documentMixin';

export default {
    name: "dropdownOptionsComponent",
    props:['items'],
    setup() {
        const {t} = useI18n();
        const toast = useToast();
        return {t, toast}
    },
    mixins:[documentMixin]
}
</script>

<style scoped>

</style>