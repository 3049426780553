<template>
    <div class="card mb-1">
        <div class="card-body pt-2 row">
            <div class=" col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6" v-if="$route.name !== 'Running' && $route.name !== 'PendingAdm'">
                <label>{{t('DOCUMENTS.DOCUMENTS_FINISHED_AT')}}:</label>
                <slot name="input_finished_doc"></slot>
            </div>
            <div class=" col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6">
                <label>{{t('DOCUMENTS.SYSTEM')}}:</label>
                <slot name="input_sistem"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import {useI18n} from 'vue-i18n';
import {useToast} from 'vue-toastification';

export default {
    name: "cardFiltersIndexComponent",
    setup() {
        const {t} = useI18n();
        const toast = useToast();
        return {t, toast}
    },
}
</script>

<style scoped>

</style>